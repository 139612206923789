import AxonifyExceptionCode from '@common/services/error/AxonifyExceptionCode';
import AxonifyExceptionFactory from '@common/services/error/AxonifyExceptionFactory';
import {
  channelsApi,
  type ChannelDetailsType
} from '@training/apps/timeline/channels/api';

const notAuthorizedError = (xhr: JQuery.jqXHR) => {
  const exception = AxonifyExceptionFactory.fromResponse(xhr);
  return exception.getErrorCode() === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED;
};

const channelDetailsApi = channelsApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      channelDetails: builder.query<ChannelDetailsType, string>({
        keepUnusedDataFor: 0,
        query: (id) => {
          return {
            apiEndpoint: `/pageCommunities/channels/${ id }`,
            skipGlobalHandler: notAuthorizedError
          };
        }
      })
    };
  }
});

export type ChannelDetailsApi = typeof channelDetailsApi;

export const {
  useChannelDetailsQuery
} = channelDetailsApi;
